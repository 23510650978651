import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import Header from "../components/Header";
import BlogCategory from "../components/BlogCategory";
import Layout from "../components/Layout";
import { mapBlogs } from "../lib/utility";

const AuthorBlogs = ({ data }) => {
    const { profile, blogs } = data;
    const { edges = [] } = blogs || {};
    let props = {
        catBlogs: mapBlogs(edges),
    };
    const { title } = profile;
    const header = (
        <Header
            active={["tools-and-resources", "bay-equity-news"]}
            theme="blue-ribbon"
        />
    );
    return (
        <Layout
            hasHero={false}
            header={header}
            path="/bay-equity-news/beyond-be">
            <Helmet>
                <title>
                    Posts by {title} - Bay Equity News | Bay Equity Home Loans
                </title>
                <meta
                    name="description"
                    content={`Check out these great blog posts by ${title}`}
                />
            </Helmet>
            <BlogCategory {...props} title={title} />
        </Layout>
    );
};
export default AuthorBlogs;

export const pageQuery = graphql`
    query blogsByOwner($id: String!, $owner: String) {
        profile(id: { eq: $id }, templateKey: { eq: "loan-officer" }) {
            id
            html
            slug
            title
            owner
        }
        blogs: allBlog(
            limit: 100
            sort: { fields: [date], order: DESC }
            filter: { author: { owner: { eq: $owner } } }
        ) {
            edges {
                node {
                    id
                    html
                    slug
                    title
                    date
                    feature
                    categories
                    tags
                    bloghero
                    image
                    author {
                        slug
                        owner
                        profile {
                            name
                        }
                        gallery {
                            photo
                        }
                    }
                }
            }
        }
    }
`;
